import React from "react"
import SEO from "../components/seo"
import Slider from '../components/Slider'
import { StaticImage } from 'gatsby-plugin-image'
import { useMediaQuery } from '@mui/material'
import { useInView } from 'react-intersection-observer'
import loadable from '@loadable/component'
const ProductLine = loadable(() => import('../components/Home/ProductLine/ProductLine'))
const VideoSection = loadable(() => import('../components/Home/VideoSection/VideoSection'))
const NewsSection = loadable(() => import('../components/Home/NewsSection/NewsSection'))


const Home = () => {
  const [galleryRef, galleryInView ] = useInView({
    triggerOnce: true,
    threshold: 0.15
  })
  const [ videoSectionRef, videoSectionInView ] = useInView({
    triggerOnce: true,
    threshold: 0.15
  })
  const [ newsSectionRef, newsSectionInView ] = useInView({
    triggerOnce: true,
    threshold: 0.15,
  })

  const matchesLg = useMediaQuery(theme => theme.breakpoints.up('lg'))
  
  return (
    <>
      <SEO 
        title="High-Quality Grain Elevators, Conveyors, and Augers | Manufacturer Website Farm Equipment Plus LLC" 
        description="Discover our reliable and durable grain handling solutions including grain elevators, grain legs, grain conveyors, farm conveyors, and augers. Contact us today for a quote!" />
      <Slider />
        <section aria-hidden={true} ref={galleryRef} style={{ minHeight: '350px'}}>
          {
            galleryInView ?
            <div
            style={{ display: 'grid', margin: 16}}
          >
            {
              matchesLg &&
              <StaticImage
              className="corn-image"
              src="../images/corn-background.jpg"
              alt=""
              placeholder="none"
              backgroundColor="#ffffff"
              formats={["auto", "webp", "avif"]}
              objectFit="contain"
              objectPosition="50% 100%"
              style={{
                gridArea: "1/1",
              }}
            />
            }
              <div style={{
                // By using the same grid area for both, they are stacked on top of each other
                gridArea: "1/1",
                position: "relative",
                // This centers the other elements inside the hero component
                placeItems: "center",
                display: "grid",
              }}>
                <ProductLine />
              </div>
            </div> :
            null
          }
        </section>
        <section ref={videoSectionRef} style={{ minHeight: '350px'}}>
          {
            videoSectionInView ?
            <VideoSection /> :
            null
          }
        </section>
        <section ref={newsSectionRef} style={{ minHeight: '350px'}}>
          {
            newsSectionInView ?
            <NewsSection /> :
            null
          }
        </section>
    </>
  )
}

export default Home
