import React, { useContext, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { HeaderHeightStateContext } from '../../context/headerHeightContext';
import Typography from '@mui/material/Typography'
import { StaticImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby';
import loadable from '@loadable/component'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
const PREFIX = 'SliderWrapper';

const classes = {
  sliderPlaceholderBackground: `${PREFIX}-sliderPlaceholderBackground`,
  content: `${PREFIX}-content`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.sliderPlaceholderBackground}`]: {
    display: 'grid',
    backgroundColor: theme.palette.secondary.light,
    height: '100%',
  },

  [`& .${classes.content}`]: {
    gridArea: "1/1",
    position: "relative",
    '@media (min-width:414px)': {
      backgroundColor: `rgba(255, 255, 181, 0.52)`,
    },
    display: 'flex', 
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center', 
    alignItems: 'center',
    padding: 16,
  }
}));

const Slider = loadable(() => import('./components/Slider'))

const SliderWrapper = () => { 
  const headerHeight = useContext(HeaderHeightStateContext)
  const [ showSlider, setShowSlider ] = useState(false)
  const [ dynamicHeight, setDynamicHeight ] = useState(`calc(100vh - 144px)`)

  const {
    backgroundImage
  } = useStaticQuery(graphql`
    query BackgroundImageQuery {
      backgroundImage: file(relativePath: {eq: "sliderPlaceholderBackground.jpg"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)



  useEffect(() => {
    if(!headerHeight) return;
    
    setDynamicHeight(`calc(100vh - ${headerHeight}px)`)
  }, [headerHeight])

  useEffect(() => {
    const handleInteraction = () => {
      document.removeEventListener('mousemove', handleInteraction);
      document.removeEventListener('scroll', handleInteraction);
      document.removeEventListener('keydown', handleInteraction);
      document.removeEventListener('click', handleInteraction);
      document.removeEventListener('touchstart', handleInteraction);
      setShowSlider(true)
    }

    document.addEventListener('mousemove', handleInteraction, { passive: true });
    document.addEventListener('scroll', handleInteraction, { passive: true });
    document.addEventListener('keydown', handleInteraction, { passive: true });
    document.addEventListener('click', handleInteraction, { passive: true });
    document.addEventListener('touchstart', handleInteraction, { passive: true });

    return handleInteraction
  }, [])

  return (
    <Root style={{
      height: dynamicHeight,
      transition: 'all 0.8s'
    }}>
      {
        showSlider && <Slider />
      }
      {
        <div className={classes.sliderPlaceholderBackground} style={{ visibility: showSlider ? 'hidden' : 'visible' }}>
          {
            !showSlider && <GatsbyImage 
            image={getImage(backgroundImage)}
            alt=""
            layout="fullWidth"
            // placeholder="blurred"
            placeholder="dominantColor"
            formats={["auto", "webp", "avif"]}
            transformOptions={{ fit: 'cover'}}
            style={{
              gridArea: "1/1",
            }}
            />
          }
          <div className={classes.content}>
            <Typography variant="h1" paragraph>Farm equipment plus LLC</Typography>
            <Typography variant="body2" component="p" align="center" color="textPrimary">
              Grain conveyors, grain elevators for your farm
            </Typography>
            <StaticImage
              src="../../images/logo-old.png"
              alt="Farm equipment plus logo"
              placeholder="dominantColor"
              layout="constrained"
              width={220}
              style={{ marginTop: 24 }}
              quality={70}
            />
          </div>
        </div>
      }
    </Root>
  );
}

export default SliderWrapper